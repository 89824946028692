import React from "react"
import loadable from '@loadable/component'
import { Row, Col, CardDeck } from "react-bootstrap"

const Layout = loadable(() => import("../components/layout"))
const Image = loadable(() => import("../components/image"))
const Sezione = loadable(() => import("../components/sezione"))
const SEO = loadable(() => import("../components/seo"))
const Bannerone = loadable(() => import("../components/bannerone"))
const Servizio = loadable(() => import("../components/servizio"))
const Galleria = loadable(() => import("../components/galleria"))
const Greencard = loadable(() => import("../components/greencard"))
const BannerClienti = loadable(() => import("../components/bannerClienti"))
const QuadroNotizia = loadable(() => import("../components/quadroNotizia"))



const servizi = [
  {
    title: "Fotovoltaico",
    link: "fotovoltaico-residenziale",
    filename: "fotovoltaico-icon",
  },
  {
    title: "Riqualificazione Energetica",
    link: "ingegneria-energetica",
    filename: "riqualificazioneEnergetica-icon",
  },
  {
    title: "Microcogenerazione ad Alto Rendimento",
    link: "cogenerazione",

    filename: "microcogenerazione-icon",
  },
  {
    title: "Certificazione e Diagnosi Energetica",
    link: "certificazione-energetica",

    filename: "certificazioneEnergetica-icon",
  },
  {
    title: "Detrazione Fiscale",
    link: "fotovoltaico-residenziale",
    filename: "detrazioneFiscale-icon",
  },
  {
    title: "Fotovoltaico Aziendale",
    link: "fotovoltaico-aziendale",
    filename: "fotovoltaicoAziende-icon",
  },
]

const evidenziati = [
  {
    title: "Speciale Fotovoltaico Aziendale",
    description: (
      <span className="text-justify">
        Super Ammortamento del 106% <br />
        Zero Anticipo e Zero Spese
      </span>
    ),
    alt: "offerta fotovoltaico aziendale lazio",
    filename: "fotovoltaico-aziende-lazio.jpg",
    link: "fotovoltaico-aziendale",
  },
  {
    title: "Bonus Alberghi",
    description:
      "Incentivi per Alberghi e agriturismo",

    filename: "../bonus-ristrutturazione-hotel-agriturismi.jpg",
    aLink: "alberghi-e-agriturismi",
  },
  {
    title: "Risparmio Energetico",
    description:
      "Interventi di Efficientamento Energetico per privati ed Aziende con verifiche termografiche ad infrarossi",
    alt: "riqualificazione energetica roma anzio nettuno latina",
    filename: "riqualificazione-energetica.jpg",
    link: "ingegneria-energetica",
  },
]

const IndexPage = () => (
  <Layout>
    <SEO
      title="Azienda del Lazio specializzata in energie rinnovabili"
      keywords={[
        `impianti fotovoltaici lazio`,
        `impianto fotovoltaico anzio`,
        `energia rinnovabile`,
        `impianto fotovoltaico nettuno`,
        `impianti fotovoltaici aprilia`,
      ]}
    />
    <Bannerone />

    <Sezione title="Soluzioni e Servizi">
      <Row>
        {servizi.map(servizio => {
          return (
            <Servizio
              title={servizio.title}
              link={servizio.link}
              filename={servizio.filename}
            />
          )
        })}
      </Row>
      <Row className="mt-2 mb-2">
        <Col lg={8} className="mb-2">
          <Greencard />
        </Col>
        <Col lg={4} className="mb-2">
          <Galleria />
        </Col>
      </Row>
    </Sezione>
    <BannerClienti />
    <Sezione title="In Evidenza">
      <CardDeck>
        {evidenziati.map(ev => {
          return (
            <QuadroNotizia
              title={ev.title}
              description={ev.description}
              filename={ev.filename}
              aLink={ev.aLink}
              alt={ev.alt}
              link={ev.link}
            />
          )
        })}
      </CardDeck>
    </Sezione>
    <Sezione />
  </Layout>
)

export default IndexPage
